import React, { useEffect, useState } from 'react'
import ComponentHeader from '../CommonComponents/componentHeader';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../CommonComponents/loadingSpinner';
import { setNotificationEmails } from '../../Actions/notificationEmailsActions';

export const NOTIFICATIONS_COMPONENT = "NOTIFICATIONS_COMPONENT";

function isValidEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

function deepEqualArrays(arr1, arr2) {
  return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
}

const EmailList = ({ emails, isLoading }) => {
  if(isLoading) {
    return <div className='pt-2 text-muted small'>
    <em>Updating...</em>
  </div>
  }
  if(emails.length === 0) {
    return <div className='pt-2 text-muted small'>
      <em>No Emails Added</em>
    </div>
  }
  return (
    <div className="d-flex flex-wrap gap-2">
      {emails.map((email, index) => (
          <span key={index} className="email-badge leading-4_5">{email}</span>
        ))}
    </div>
  );
};

const EmailInputs = ({initialEmails, submitHandler}) => {
  const initialEmailObjects = initialEmails.map((email, index) => {
    return {
      key: index,
      value: email,
    };
  });
  const [emailInputs, setEmailInputs] = useState(initialEmailObjects);
  const [newEmail, setNewEmail] = useState('');

  const handleDelete = (key) => {
    const updatedEmails = emailInputs.filter((email) => key !== email.key);
    setEmailInputs(updatedEmails);
  };

  const handleAddInput = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      if(isValidEmail(newEmail)) {
        const newKey = emailInputs.reduce((prev, cur) => Math.max(prev, cur.key), 0) + 1;
        setEmailInputs([...emailInputs, {value: newEmail, key: newKey}]);
        setNewEmail('');
      }  
    }
  };

  const canSave = !deepEqualArrays(emailInputs, initialEmailObjects);

  const handleSave = (e) => {
    e.preventDefault();
    submitHandler(emailInputs.map(email => email.value));
  };

  const sortedEmails = emailInputs.sort((a, b) => a.key - b.key);

  return (
    <form onSubmit={handleSave}>
      <div className="email-inputs d-flex flex-wrap gap-2">
        {sortedEmails.map((email) => (
          <div key={email.key} className="email-badge leading-4_5">
            {email.value}{" "}
            <span
              className="btn p-0 m-0 leading-4_5"
              onClick={() => handleDelete(email.key)}
            >
              &#x2715;
            </span>
          </div>
        ))}
        {emailInputs.length < 10 && <input
          type="text"
          className="block"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          onKeyDown={handleAddInput}
          placeholder="Enter Email"
        />}
      </div>
      {canSave && (
        <div className="mt-2">
          <button className="btn btn-success" type="submit" disabled={newEmail.length > 0}>
            <i className="bi bi-check-circle"></i> Save
          </button>
        </div>
      )}
    </form>
  );
}

const NotificationsWrapper = () => {
    const emailsObj = useSelector((state) => state.notificationEmails); 
    const dispatch = useDispatch();

    const emails = emailsObj?.getEmails() ?? [];
    
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };   

    const submitEmails = (newEmails) => {
        setIsLoading(true);
        dispatch(setNotificationEmails(newEmails));
        setIsEditing(false);
    }

    useEffect(() => {
        if(emailsObj) setIsLoading(false);
    }, [emailsObj]);

    return (
        <>
            <ComponentHeader title={"Notifications"}/>
            <div className={"mt-4 d-flex flex-column" + (isLoading ? " opacity-50": "")}>
                <span className="sub-title">Report Emails</span>
                <div className='mb-2 d-flex justify-content-between align-items-center'>
                    <div>Manage the email addresses where you want to receive weekly report emails for your companies</div>
                    {isEditing ?
                        <button className={"btn" + (isLoading ? " invisible" : "")} onClick={handleCancel}>
                            <span className='text-danger'><i className="bi bi-x-circle"></i> Cancel</span>
                        </button> 
                    : 
                        <button className={"btn" + (isLoading ? " invisible" : "")} onClick={handleEdit}>
                            <span className='text-success'><i className="bi bi-pencil"></i> Edit</span>
                        </button> 
                    }
                </div>
                {isEditing ?
                    <EmailInputs initialEmails={emails} submitHandler={submitEmails} /> : 
                    <EmailList emails={emails} isLoading={isLoading} />}

                {isLoading && <div>
                    <LoadingSpinner/>
                </div>}
            </div>
        </>
    );
}

export default NotificationsWrapper;