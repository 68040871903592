import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../Actions/loginAction";
import {BILLING_COMPONENT} from "../Billing/billingPageWrapper";
import {DASHBOARD_COMPONENT} from "../Dashboard/dashboardWrapper";
import {constructSelectedComponent} from "../../Factory/factory"
import {PROFILE_COMPONENT} from "../Profile/profilePageWrapper";
import {ANALYTICS_COMPONENT} from "../Analytics/analyticsWrapper";
import {PAYMENT_COMPONENT} from "../Payments/paymentsWrapper";
import {getOrgNameBasedOnLevel} from "../../Util/dataHelper";
import { NOTIFICATIONS_COMPONENT } from "../Notifications/notificationsWrapper";
import { ADMIN } from "../../Enums/accessLevels";
import { ENTERPRISE } from "../../Enums/administratorLevels";
// import {PAYMENT_SETUP} from "../PaymentSetup/paymentSetupWrapper";

const LEFT_SIDE_BAR = "LEFT_SIDE_BAR";
const LEFT_SIDE_BAR_SELECTED = "LEFT_SIDE_BAR_SELECTED";
const PARTNER_TYPE_AFFILIATE = "AFFILIATE";
const PARTNER_TYPE_RESELLER = "RESELLER";

const LeftNavbar = () => {
    const sideBarActive = useSelector(state => state.leftSideBarActive);
    const partnerDetails = useSelector(state => state.partnerDetails);
    const globalSelectedComponent = useSelector(state => state.selectedComponent);
    const [selectedComponent, setSelectedComponent] = useState(ANALYTICS_COMPONENT);
    const dispatch = useDispatch();
    const setActiveComponent = (component) => {
        dispatch({
            type: LEFT_SIDE_BAR_SELECTED,
            payload: constructSelectedComponent(component)
        })
    }

    const showEarning = () => {
        if (partnerDetails.partnerType === PARTNER_TYPE_AFFILIATE) return true;
        else return false;
    }

    const showNotifications = () => {
        return (partnerDetails.getAccessLevel() === ADMIN && partnerDetails.getAdminLevel() === ENTERPRISE);
    }

    useEffect(() => {
        if (globalSelectedComponent !== null && globalSelectedComponent?.component !== selectedComponent) {
            setSelectedComponent(globalSelectedComponent.component);
        }
    }, [globalSelectedComponent?.component])

    if (partnerDetails === null) return <></>

    return (
        <div
            className={`side-navbar d-flex justify-content-between flex-wrap flex-column ${sideBarActive ? "activeNav" : ""}`}>
            <ul className="nav list-group flex-column w-100">
                {/*<div className="text-end px-2 fs-3 closeNavBar" role="button" onClick={closeNavBar}><i*/}
                {/*    className="bi bi-x"></i></div>*/}
                {/*<div className="h3 text-white px-3 py-0">*/}
                {/*    {getOrgNameBasedOnLevel(partnerDetails, enterpriseLevelsDetails)}*/}
                {/*</div>*/}
                {/*<div className="text-white ps-3 pe-2 pt-0 pb-3 text-break w-100 text-truncate">*/}
                {/*    {partnerDetails.email}*/}
                {/*</div>*/}
                <li className={`nav-link ${selectedComponent === ANALYTICS_COMPONENT ? "nav-selected": ""}`} onClick={() => {
                    setActiveComponent(ANALYTICS_COMPONENT)
                }}>
                    <i className="bi bi-graph-up"></i><span className="mx-2">Analytics</span>
                </li>
                <li className={`nav-link ${selectedComponent === DASHBOARD_COMPONENT ? "nav-selected": ""}`} onClick={() => {
                    setActiveComponent(DASHBOARD_COMPONENT)
                }}>
                    <i className="bi bi-view-stacked"></i><span className="mx-2">Accounts</span>
                </li>
                {!showEarning() && <li className={`nav-link ${selectedComponent === BILLING_COMPONENT ? "nav-selected": ""}`} onClick={() => {
                    setActiveComponent(BILLING_COMPONENT)
                }}>
                    <i className="bi bi-receipt"></i><span className="mx-2">Billing & Usage</span>
                </li>
                }
                {/*{showEarning() && <li className="nav-link" onClick={() => {*/}
                {/*    setActiveComponent(EARNING_COMPONENT)*/}
                {/*}}>*/}
                {/*    <span className="mx-2">Earnings</span>*/}
                {/*</li>}*/}
                {/*{!showEarning() && <li className="nav-link" onClick={() => {*/}
                {/*    setActiveComponent(PAYMENT_COMPONENT)*/}
                {/*}}>*/}
                {/*    <span className="mx-2">Payments</span>*/}
                {/*</li>*/}
                {/*}*/}
                {/*{showEarning() && <li className="nav-link" onClick={() => {*/}
                {/*    setActiveComponent(PAYOUTS_COMPONENT)*/}
                {/*}}>*/}
                {/*    <span className="mx-2">Payouts</span>*/}
                {/*</li>}*/}
                <li className={`nav-link ${selectedComponent === PAYMENT_COMPONENT ? "nav-selected": ""}`} onClick={() => {
                    setActiveComponent(PAYMENT_COMPONENT)
                }}>
                    <i className="bi bi-credit-card"></i><span className="mx-2">Payment</span>
                </li>
                {showNotifications() && <li className={`nav-link ${selectedComponent === NOTIFICATIONS_COMPONENT ? "nav-selected": ""}`} onClick={() => {
                    setActiveComponent(NOTIFICATIONS_COMPONENT)
                }}>
                    <i className="bi bi-megaphone"></i><span className="mx-2">Notifications</span>
                </li>}
                <li className={`nav-link ${selectedComponent === PROFILE_COMPONENT ? "nav-selected": ""}`} onClick={() => {
                    setActiveComponent(PROFILE_COMPONENT)
                }}>
                    <i className="bi bi-person"></i><span className="mx-2">Profile</span>
                </li>
                <li className="nav-link" onClick={() => dispatch(logout())}>
                    <i className="bi bi-box-arrow-right"></i><span className="mx-2">Logout</span>
                </li>
            </ul>
        </div>
    )
}

export default LeftNavbar;
export {LEFT_SIDE_BAR, LEFT_SIDE_BAR_SELECTED, PARTNER_TYPE_AFFILIATE, PARTNER_TYPE_RESELLER};
