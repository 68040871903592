import { constructEmails } from "../Factory/factory";
import { NOTIFICATION_EMAILS } from "../Reducers/notificationEmailsReducer";
import { findNotificationEmails, updateNotificationEmails } from "../Util/queries";

export const getNotificationEmails = () => async (dispatch) => {
    let emailsObj
    try {
        const response = await findNotificationEmails();
        emailsObj = constructEmails(response.data);  
    } catch (e) {
        console.error(e);
        emailsObj = constructEmails([]);  
    }

    dispatch({
        type: NOTIFICATION_EMAILS,
        payload: emailsObj,
    })
};

export const setNotificationEmails = (emails) => async (dispatch) => {
    try {
        const response = await updateNotificationEmails(emails);
        const emailsObj = constructEmails(response.data);
        dispatch({
            type: NOTIFICATION_EMAILS,
            payload: emailsObj,
        })
    } catch (e) {
        console.error(e);
    }
}