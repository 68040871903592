export const NOTIFICATION_EMAILS = "NOTIFICATION_EMAILS";

export default (state = null, action) => {
    switch (action.type) {
        case NOTIFICATION_EMAILS:
            return action.payload;
        default:
            return state;
    }
}
